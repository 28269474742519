import { useEffect, useState } from 'react';
import { RiFacebookFill, RiInstagramFill, RiLinkedinBoxFill, RiTwitterFill, RiYoutubeFill } from 'react-icons/ri'
import { SOCIAL_LINKS_API } from '../../../Utilities/APIs';

import './SocialLinks.css';

function SocialLinks() {

  const [fbLink, setFbLink] = useState(null);
  const [fbVisibility, setFbVisibility] = useState(null);
  const [twitterLink, setTwitterLink] = useState(null);
  const [twitterVisibility, setTwitterVisibility] = useState(null);
  const [liLink, setLiLink] = useState(null);
  const [liVisibility, setLiVisibility] = useState(null);
  const [instaLink, setInstaLink] = useState(null);
  const [instaVisibility, setInstaVisibility] = useState(null);
  const [youtubeLink, setYoutubeLink] = useState(null);
  const [youtubeVisibility, setYoutubeVisibility] = useState(null);

  async function fetchData() {
    const res = await fetch(SOCIAL_LINKS_API);
    const data = await res.json();

    const fbObj = data?.find(item => item?.platform === 'facebook')
    setFbVisibility(fbObj?.visibility)
    setFbLink(fbObj?.link)

    const twitterObj = data?.find(item => item?.platform === 'twitter')
    setTwitterVisibility(twitterObj?.visibility)
    setTwitterLink(twitterObj?.link)

    const linkedInObj = data?.find(item => item?.platform === 'linkedin')
    setLiVisibility(linkedInObj?.visibility)
    setLiLink(linkedInObj?.link)

    const instaObj = data?.find(item => item?.platform === 'instagram')
    setInstaVisibility(instaObj?.visibility)
    setInstaLink(instaObj?.link)

    const youtubeObj = data?.find(item => item?.platform === 'youtube')
    setYoutubeVisibility(youtubeObj?.visibility)
    setYoutubeLink(youtubeObj?.link)

  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='social_wrapper'>

      {fbVisibility &&
        <a href={fbLink} rel="noopener noreferrer" target="_blank" className='facebook'>
          <RiFacebookFill />
        </a>
      }

      {instaVisibility &&
        <a href={instaLink} rel="noopener noreferrer" target="_blank" className='youtube'>
          <RiInstagramFill />
        </a>
      }
      {liVisibility &&
        <a href={liLink} rel="noopener noreferrer" target="_blank" className='linkedin'>
          <RiLinkedinBoxFill />
        </a>
      }

      {twitterVisibility &&
        <a href={twitterLink} rel="noopener noreferrer" target="_blank" className='twiter'>
          <RiTwitterFill />
        </a>
      }

      {youtubeVisibility &&
        <a href={youtubeLink} rel="noopener noreferrer" target="_blank" className='youtube'>
          <RiYoutubeFill />
          LinkedIn
        </a>
      }
    </div>
  )
}

export default SocialLinks