
export const BASE_URL = process.env.REACT_APP_BACKEND_URL
export const IMAGE_URL = process.env.REACT_APP_SPACES_URL
export const API_URL = BASE_URL + 'api/'
 
export const CLIENT_JOURNEY_API = API_URL + 'clientJourney/'
export const ABOUT_API = API_URL + 'about/'
export const APPROACH_API = API_URL + 'approach/'
export const MEET_THE_TEAM_API = API_URL + 'meetTheTeam/'
export const PROCESS_API = API_URL + 'process/'
export const RECENT_PURCHASE_API = API_URL + 'recentPurchase/'
export const FEATURED_RECENT_PURCHASE_API = API_URL + 'featuredRecentPurchase/'
export const RECENT_PURCHASE_FEATURES_API = API_URL + 'recentPurchaseFeatures/'
export const TESTIMONIAL_API = API_URL + 'testimonial/'
export const FEATURED_TESTIMONIAL_API = API_URL + 'featuredTestimonial/'
export const FAQ_API = API_URL + 'faq/'
export const RESOURCE_API = API_URL + 'resource/'

export const CONTACT_INFOS_API = API_URL + 'contactInfo/' 
export const CONTACTS_API = API_URL + 'contact/'
export const SOCIAL_LINKS_API = API_URL + 'socialLink/'