import { createContext, useState } from "react";

const AppContext = createContext({})

function AppContextProvider({ children }) {

    const [showConsultationForm, setShowConsultationForm] = useState(false);
    const [showSingleResource, setShowSingleResource] = useState(false);


    return (
        <AppContext.Provider
            value={{
                showConsultationForm,
                setShowConsultationForm,
                showSingleResource, 
                setShowSingleResource
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export {AppContextProvider}

export default AppContext;