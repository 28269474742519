import { useContext, useState } from 'react';
import axios from 'axios';
import { CONTACTS_API } from '../../../Utilities/APIs';
import { toast } from 'react-toastify';
import AppContext from '../../../AppContext/AppContext';

function ConsultationForm() {

    const { setShowConsultationForm } = useContext(AppContext)

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [date, setDate] = useState('');
    const [financePreApproved, setFinancePreApproved] = useState('');
    const [message, setMessage] = useState('')
    const [error, setError] = useState(null)

    async function handleSubmit(e) {
        e.preventDefault();

        setError(null);

        if (!firstName || !lastName || !email || !phone || !date || !financePreApproved || !message) {
            setError("Please Add All Field");
            return;
        }

        const itemData = {
            firstName,
            lastName,
            email,
            phone,
            date,
            financePreApproved,
            message
        };

        try {
            const profileData = await axios.post(CONTACTS_API, itemData);

            if (profileData.status === 200) {
                toast.success('Request successfully received');
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setDate('');
                setFinancePreApproved('');
                setMessage('');
                setShowConsultationForm(false);
                setError('');
            }
        } catch (error) {
            if (error?.response && error?.response?.data) {
                setError(error?.response?.data?.message || 'An error occurred');
            } else {
                setError('An error occurred. Please try again later.');
            }
        }
    }


    return (
        <div className="form_wrapper">
            <form onSubmit={handleSubmit}>
                <div className="grid_2">
                    <div className="input_group">
                        <label className='input_label'>First Name</label>
                        <input
                            className='input_field'
                            type="text"
                            placeholder='Enter your first name'
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input_group">
                        <label className='input_label'>Last Name</label>
                        <input
                            className='input_field'
                            type="text"
                            placeholder='Enter your last name'
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="input_group">
                    <label className='input_label'>Email</label>
                    <input
                        className='input_field'
                        type="email"
                        placeholder='Enter your email address'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="input_group">
                    <label className='input_label'>Phone</label>
                    <input
                        className='input_field'
                        type="text"
                        placeholder='Enter your phone number'
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        required
                    />
                </div>
                <div className="input_group">
                    <label className='input_label'>When would you look like to be contacted?</label>
                    <input
                        className='input_field date'
                        type="date"
                        placeholder='Enter your phone number'
                        value={date}
                        onChange={e => setDate(e.target.value)}
                        required
                    />
                </div>
                <div className="input_group">
                    <label className='input_label'>Do you have a Finance Pre Approval?</label>
                    <select
                        className='input_field select'
                        value={financePreApproved}
                        onChange={e => setFinancePreApproved(e.target.value)}
                        required
                    >
                        <option value="0">Select one</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div className="input_group">
                    <label className='input_label'>Anything else you would like to mention?</label>
                    <textarea
                        rows="4"
                        className='input_field '
                        placeholder='Write your message'
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        required
                    ></textarea>
                </div>
                {error && <p style={{ color: "red", fontSize: '14px' }}>{error}</p>}
                <button
                    type="submit"
                    className='btn fill'
                >
                    <span>Book Now</span>
                </button>
            </form>
        </div>
    )
}

export default ConsultationForm