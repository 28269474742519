import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import { FEATURED_TESTIMONIAL_API, IMAGE_URL } from '../../../../Utilities/APIs';

// import comePic from '../../../../Assets/Images/tst-user.png'

import './FeaturedTestimonials.css'

function FeaturedTestimonials() {

  const [featuredTestimonials, setFeaturedTestimonials] = useState(null);

  useEffect(() => {
    async function getFeaturedTestimonials() {
      const { data } = await axios.get(FEATURED_TESTIMONIAL_API)
      setFeaturedTestimonials(data)
    }

    getFeaturedTestimonials()
  }, [])


  return (
    <section className='featured_testimonials py_9'>
      <div className="container">
        <div className="flex_container">
          <div className="flex_item">
            <div className="content"> 
              <h1 className="title_1">Hear What <br />
                Our <span>Clients</span> Say</h1>
              <p>Read what our clients have to say about their experience working with us in the testimonial section below.</p>
              <Link to='/testimonials' className='btn'><span>see more</span></Link>
            </div>
          </div>
          <div className="flex_item">
            <div className="testimonials">
              {featuredTestimonials &&
                featuredTestimonials?.map(featuredTestimonial => (
                  <div className="item" key={featuredTestimonial?._id}>
                    <h6>{featuredTestimonial?.testimonial?.description?.slice(0, 300)}</h6>
                    <Link to={'/singleTestimonial/' + featuredTestimonial?.testimonial?._id}>read more</Link>
                    <div className="thumb_name">
                      <div>
                        <img src={IMAGE_URL + featuredTestimonial?.testimonial?.image} alt="poster" />
                      </div>
                      <h5>{featuredTestimonial?.testimonial?.name}</h5>
                    </div>
                  </div>
                ))}
            </div>
            <div className="sm_only">
              <Link to='/testimonials' className='btn'><span>see more</span></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedTestimonials