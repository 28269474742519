import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom'
import AppContext from '../../../../AppContext/AppContext'

import { ReactComponent as MenuIcon } from '../../../../Assets/Icons/menu-icon.svg'
import logoLg from '../../../../Assets/Logos/nav-logo-lg.svg'
import logoSm from '../../../../Assets/Logos/nav-logo-sm.svg'

import './NavLarge.css'

function NavLarge({ smallNavToggle }) {

    const { setShowConsultationForm } = useContext(AppContext)

    return (
        <div className="container">
            <div className='nav_large'>
                <Link to='/' className='logo_wrapper'>
                    <img src={logoLg} alt="brand" className="logo_lg" />
                    <img src={logoSm} alt="brand" className="logo_sm" />
                </Link>
                <nav>
                    <NavLink to='/'>home</NavLink>
                    <NavLink to='/aboutUs'>About Us</NavLink>
                    <NavLink to='/meetTheTeam'>Meet The Team</NavLink>
                    <NavLink to='/process'>Process</NavLink>
                    <NavLink to='/recentPurchase'>Recent Purchase</NavLink>
                    <NavLink to='/testimonials'>Testimonials</NavLink>
                    <NavLink to='/contactUs'>Contact Us</NavLink>
                    <button type='button' className='btn fill' onClick={() => { setShowConsultationForm(true) }}>
                        <span>Free Consultation</span>
                    </button>
                </nav>
                <button className="btn_nst" onClick={smallNavToggle}>
                    <MenuIcon />
                </button>
            </div>
        </div>
    )
}

export default NavLarge