import ClientJourny from './ClientJourny/ClientJourny'
import FeaturedProcess from './FeaturedProcess/FeaturedProcess'
import FeaturedPurchases from './FeaturedPurchases/FeaturedPurchases'
import FeaturedTestimonials from './FeaturedTestimonials/FeaturedTestimonials'
import VideoBanner from './VideoBanner/VideoBanner'

import './Home.css'

function Home() {
  return (
    <main className='home_page'>
      <VideoBanner />
      <ClientJourny isHeading />
      <FeaturedPurchases />
      <FeaturedProcess />
      <FeaturedTestimonials />
    </main>
  )
}

export default Home