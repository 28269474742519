import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppContext from './AppContext/AppContext';

import './App.css';

import Navbar from './Components/Partials/Navbar/Navbar';
import Home from './Components/Screens/Home/Home';
import AboutUs from './Components/Screens/AboutUs/AboutUs';
import MeetTheTeam from './Components/Screens/MeetTheTeam/MeetTheTeam';
import Process from './Components/Screens/Process/Process';
import RecentPurchase from './Components/Screens/RecentPurchase/RecentPurchase';
import Testimonials from './Components/Screens/Testimonials/Testimonials';
import SingleTestimonial from './Components/Screens/SingleTestimonial/SingleTestimonial';
import ContactUs from './Components/Screens/ContactUs/ContactUs';
import ConsultationForm from './Components/Layouts/ConsultationForm/ConsultationForm';
import FAQs from './Components/Screens/FAQs/FAQs';
import Resources from './Components/Screens/Resources/Resources';
import PrivacyPolicy from './Components/Screens/PrivacyPolicy/PrivacyPolicy';
import TermsService from './Components/Screens/TermsService/TermsService';
import Footer from './Components/Partials/Footer/Footer';
import Modal from './Components/Layouts/Modal/Modal';
import ScrollTop from './ScrollTop';

function App() {

  const { showConsultationForm } = useContext(AppContext)

  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutUs' element={<AboutUs />} />
          <Route path='/meetTheTeam' element={<MeetTheTeam />} />
          <Route path='/process' element={<Process />} />
          <Route path='/recentPurchase' element={<RecentPurchase />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/singleTestimonial/:id' element={<SingleTestimonial />} />
          <Route path='/contactUs' element={<ContactUs />} />
          <Route path='/faq' element={<FAQs />} />
          <Route path='/resources' element={<Resources />} />
          <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
          <Route path='/termsService' element={<TermsService />} />
        </Routes>
        <Footer />

        {showConsultationForm &&
          <Modal isHeading>
            <ConsultationForm/>
          </Modal>
        }

      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
