import { useEffect, useState } from 'react';
import axios from 'axios';
import { MEET_THE_TEAM_API } from '../../../Utilities/APIs';

// import Banner from '../../Layouts/Banner/Banner'
import MTTCard from './MTTCard/MTTCard'

import './MeetTheTeam.css'

function MeetTheTeam() {

  const [mTTeams, setMTTeams] = useState(null);

  useEffect(() => {
    async function getMTTeams() {
      const { data } = await axios.get(MEET_THE_TEAM_API)
      setMTTeams(data)
    }

    getMTTeams()
  }, [])


  return (
    <main className="mtt_page">
      {/* <Banner title="Meet The Team" /> */}
      <section className="mt_team py_9">
        <div className="container">
          {mTTeams &&
            mTTeams?.map(mTTeam => (
              <MTTCard
                key={mTTeam._id}
                mTTeam={mTTeam}
              />
            ))}
        </div>
      </section>
    </main>
  )
}

export default MeetTheTeam