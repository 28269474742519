import axios from 'axios';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { RiWhatsappLine } from 'react-icons/ri';
import { CONTACTS_API, CONTACT_INFOS_API } from '../../../Utilities/APIs';
import { ReactComponent as IconEmail } from '../../../Assets/Icons/mail.svg'
import SocialLinks from '../../Layouts/SocialLinks/SocialLinks'
import './ContactUs.css'

function ContactUs() {

  const [contactInfo, setContactInfo] = useState({});

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [date, setDate] = useState('');
  const [financePreApproved, setFinancePreApproved] = useState('');
  const [message, setMessage] = useState('')
  const [error, setError] = useState(null)


  const navigate = useNavigate()

  useEffect(() => {
    async function getContactInfo() {
      const { data } = await axios.get(CONTACT_INFOS_API)
      setContactInfo(data[0])
    }

    getContactInfo()
  }, [])


  async function handleSubmit(e) {

    e.preventDefault()
    setError(null);

    if (!firstName || !lastName || !email || !phone || !date || !financePreApproved || !message) {
      setError("Please Add All Field");
      return;
    }


    const itemData = {
      firstName,
      lastName,
      email,
      phone,
      date,
      financePreApproved,
      message
    }

    const profileData = await axios.post(CONTACTS_API, itemData)

    if (profileData.status === 200) {
      navigate('/')
      toast.success('Request successfully received')
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhone('')
      setDate('')
      setFinancePreApproved('')
      setMessage('')
    }
  }


  return (
    <main className="contact_us_page">
      {/* <Banner title="Contact Us" /> */}
      <section className="contact_us py_9">
        <div className="container">
          <div className="grid_container">
            <div className="grid_item">
              <h1 className="title_1">Get <span>In</span> Touch</h1>
              <p>Have questions or ready to get started? Reach out to us via phone, email or fill out the form. We're here to help you in your property journey.</p>

              <div className="contacts_wrapper">
                <div className='mail'>
                  <h6>E-mail:</h6>
                  <a href={`mailto:${contactInfo && contactInfo.email}`}>
                    <IconEmail />
                    {contactInfo && contactInfo.email}
                  </a>
                </div>
                <div className='phone'>
                  <h6>Phone:</h6>
                  {/* <a href={`tel:${contactInfo && contactInfo.phone}`}>
                    <IconPhone />
                    {contactInfo && contactInfo.phone}
                  </a> */}
                  <a href="https://api.whatsapp.com/send?phone=01723942179" target='_blanck'>
                    <RiWhatsappLine />
                    61 433 112 316
                  </a>
                </div>
                <div className='mail'>
                  <h6>Social:</h6>
                  <SocialLinks />
                </div>
              </div>
            </div>
            <div className="grid_item">
              <div className="form_wrapper">
                <form onSubmit={handleSubmit}>
                  <div className="grid_2">
                    <div className="input_group">
                      <label className='input_label'>First Name</label>
                      <input
                        className='input_field'
                        type="text"
                        placeholder='Enter your first name'
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_group">
                      <label className='input_label'>Last Name</label>
                      <input
                        className='input_field'
                        type="text"
                        placeholder='Enter your last name'
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_group">
                      <label className='input_label'>Email</label>
                      <input
                        className='input_field'
                        type="email"
                        placeholder='Enter your email address'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_group">
                      <label className='input_label'>Phone</label>
                      <input
                        className='input_field'
                        type="text"
                        placeholder='Enter your phone number'
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_group">
                      <label className='input_label'>When would you like to be contacted?</label>
                      <input
                        className='input_field date'
                        type="date"
                        placeholder='Enter your phone number'
                        value={date}
                        onChange={e => setDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_group">
                      <label className='input_label'>Do you have a Finance pre-approval ?</label>
                      <select
                        className='input_field select'
                        value={financePreApproved}
                        onChange={e => setFinancePreApproved(e.target.value)}
                        required
                      >
                        <option value="0">Select one</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="input_group">
                    <label className='input_label'>Anything else you would like to mention?</label>
                    <textarea
                      rows="6"
                      className='input_field '
                      placeholder='Write your message'
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>

                  {error && <p style={{ color: "red", fontSize: '14px' }}>{error}</p>}

                  <button
                    type="submit"
                    className='btn fill'
                  >
                    <span>Book Now</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default ContactUs