import { Link } from 'react-router-dom'
import logoImg from '../../../Assets/Logos/nav-logo-lg.svg'

import './Footer.css'
import SocialLinks from '../../Layouts/SocialLinks/SocialLinks'

function Footer() {
  return (
    <footer className='footer'>
      <div className="container">
        <div className="footer_main">
          <div className="logo_text_wrapper">
            <img src={logoImg} alt="brand" />
            <p>Sourcing high growth, maximum cashflow property
              analysing 15,000+ suburbs, right across Australia.</p>
          </div>
          <div className="links_wrapper">
            <Link to='/aboutUs'>About Us</Link>
            <Link to='/meetTheTeam'>Meet The Team</Link>
            <Link to='/process'>Process</Link>
            <Link to='/recentPurchase'>Recent Purchase</Link>
            <Link to='/testimonials'>Testimonials</Link>
            <Link to='/contactUs'>Contact Us</Link>
          </div>
          <div className="links_wrapper">
            <Link to='/faq'>FAQ’s</Link>
            <Link to='/resources'>Resources</Link>
            <Link to='/privacyPolicy'>Privacy Policy</Link>
            <Link to='/termsService'>Terms of Service</Link>
          </div>
          <SocialLinks />
        </div>
        <div className="footer_bottom">
          <p>Powered by Leverage Listings</p>
          <p>Copyright © 2023 Acceler Investing. All Rights Reserved</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer