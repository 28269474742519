import starImg from '../../../../Assets/Images/star.svg'

import './ProcessCard.css'

function ProcessCard({proces}) {
    return (
        <div className='process_card'>
            <div className="item">
                <div className="img_box">
                    <img src={starImg} alt="shape" />
                    <div className="content">
                        <h1>0{proces && proces.precedence}</h1>
                    </div>
                </div>
            </div>
            <div className="item">
                <h5>{proces && proces.name}</h5>
                <p>{proces && proces.description}</p>
            </div>
        </div>
    )
}

export default ProcessCard