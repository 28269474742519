import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { RESOURCE_API } from '../../../Utilities/APIs';
import AppContext from '../../../AppContext/AppContext';
import ResCard from './ResCard/ResCard'
import SingleResource from './SingleResource/SingleResource';
import Modal from '../../Layouts/Modal/Modal';

import './Resources.css'

function Resources() {

    const { showSingleResource } = useContext(AppContext)

    const [resources, setResources] = useState(null);
    const [parentId, setParentId] = useState('');
    console.log(parentId)

    useEffect(() => {

        async function getResources() {
            const { data } = await axios.get(RESOURCE_API);
            setResources(data);
        }
        getResources()

    }, [])


    return (
        <>
            <section className='resources py_9'>
                <div className="container">
                    <h1 className="title_1 center pb_9">Resources</h1>
                    <div className="grid_container">
                        {resources &&
                            resources?.map(resource => (
                                <ResCard
                                    resource={resource}
                                    setParentId={setParentId}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>

            {showSingleResource &&
                <Modal
                extraClass="large"
                >
                    <SingleResource
                        parentId={parentId}
                    />
                </Modal>
            }
        </>
    )
}

export default Resources