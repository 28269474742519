import { useContext } from 'react';
import AppContext from '../../../../AppContext/AppContext';

import './VideoBanner.css'

function VideoBanner() {

    const { setShowConsultationForm } = useContext(AppContext)

    return (
        <section className="video_banner">
            <div className="container">
                <div className="bg_video">
                    <video autoPlay loop muted playsInline>
                        <source src='https://acceler.syd1.cdn.digitaloceanspaces.com/bg-video.mp4' />
                    </video>
                    <div className="content">
                        <h2>Unlock the Potential of Australian Property Market</h2>
                        <p>Data Driven Property Advisory Solutions,</p>
                        <p>Matching property investors to source high growth, maximum cash flow property analysing 15,000+ Suburbs using data.</p>
                        <button className="btn" onClick={() => setShowConsultationForm(true)}>
                            <span>book a call</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoBanner