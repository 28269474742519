import { useContext } from 'react';
import { RiCloseLine } from 'react-icons/ri'
import AppContext from '../../../AppContext/AppContext';

import './Modal.css';

function Modal({ isHeading, children , extraClass}) {

    const { setShowConsultationForm, setShowSingleResource } = useContext(AppContext)

    return (
        <div className={`modal ${extraClass}`}>
            <div className="modal_backdrop" onClick={() => {
                setShowConsultationForm(false)
                setShowSingleResource(false)
            }}
            ></div>
            <div className={`modal_dialog modal_dialog_centered modal_dialog_scrollable`}>
                <div className="modal_content">
                    <button type='button' className='modal_close' onClick={() => {
                        setShowConsultationForm(false)
                        setShowSingleResource(false)
                    }}
                    ><RiCloseLine /></button>
                    {isHeading &&
                        <h1 className="title_1">Book A <span>Free </span> Consultation</h1>
                    }
                    <div className="modal_body">{children}</div>
                </div>
            </div>
        </div>
    )
}
export default Modal