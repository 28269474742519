import { useEffect, useState } from 'react';
import axios from 'axios';
import { PROCESS_API } from '../../../../Utilities/APIs';

import { ReactComponent as Star } from '../../../../Assets/Images/star.svg'

import './FeaturedProcess.css'

function FeaturedProcess() {

  const [process, setProcess] = useState(null);

  useEffect(() => {
    async function getProcess() {
      const { data } = await axios.get(PROCESS_API)
      setProcess(data)
    }

    getProcess()
  }, [])


  return (
    <section className="featured_process py_9">
      <div className="container">
        <h1 className="title_1 center pb_9">Our <span>7-Step</span> Process</h1>
        <div className="grid_wrappers">
          <div className="first_grid">
            {process &&
              process?.slice(0, 4).map(proces => (
                <div className="gird_item" key={proces._id}>
                  <Star />
                  <div className="content">
                    <h1>0{proces.precedence}</h1>
                    <h6>{proces.name}</h6>
                  </div>
                </div>
              ))}
            {/* <div className="gird_item">
              <Star />
              <div className="content">
                <h1>01</h1>
                <h6>Portfolio review,
                  strategy design &
                  mentoring</h6>
              </div>
            </div>
            <div className="gird_item">
              <Star />
              <div className="content">
                <h1>02</h1>
                <h6>Research location</h6>
              </div>
            </div>
            <div className="gird_item">
              <Star />
              <div className="content">
                <h1>03</h1>
                <h6>Sourcing properties</h6>
              </div>
            </div>
            <div className="gird_item">
              <Star />
              <div className="content">
                <h1>04</h1>
                <h6>Conducting due
                  diligence</h6>
              </div>
            </div> */}
          </div>
          <div className="second_grid">
          {process &&
              process?.slice(4, 7).map(proces => (
                <div className="gird_item" key={proces._id}>
                  <Star />
                  <div className="content">
                    <h1>0{proces.precedence}</h1>
                    <h6>{proces.name}</h6>
                  </div>
                </div>
              ))}
            {/* <div className="gird_item">
              <Star />
              <div className="content">
                <h1>05</h1>
                <h6>Settlement
                  process support</h6>
              </div>
            </div>
            <div className="gird_item">
              <Star />
              <div className="content">
                <h1>06</h1>
                <h6>Introduction to
                  property managers,
                  solicitors, insurers</h6>
              </div>
            </div>
            <div className="gird_item">
              <Star />
              <div className="content">
                <h1>07</h1>
                <h6>Tenant selection
                  process</h6>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedProcess