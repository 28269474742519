import { IMAGE_URL } from "../../../../../Utilities/APIs"
import imgCj from '../../../../../Assets/Images/client-journey.png'

import './CJLarge.css'

function CJLarge({ clientJournies, isHeading }) {
    return (
        <section className="client_journey large py_9">
            <div className="container">
                {isHeading &&
                    <h1 className="title_1 center pb_9">Client <span>Journey</span></h1>
                }
                <div className="cj_grids">
                    <div className="first_grid">
                        {clientJournies &&
                            clientJournies?.slice(0, 3).map(clientJourney => (
                                <div className="grid_item" key={clientJourney._id}>
                                    <div className="content">
                                        <h5>{clientJourney.name}</h5>
                                        <p>{clientJourney.description}</p>
                                        <div className='line'></div>
                                        <div className="icon_box">
                                            <img src={IMAGE_URL + clientJourney.image} alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="second_grid">
                        {clientJournies &&
                            clientJournies?.slice(3, 7).map(clientJourney => (
                                <div className="grid_item" key={clientJourney._id}>
                                    <div className="content">
                                        <div className="icon_box">
                                            <img src={IMAGE_URL + clientJourney.image} alt="icon" />
                                        </div>
                                        <div className='line'></div>
                                        <h5>{clientJourney.name}</h5>
                                        <p>{clientJourney.description}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <img src={imgCj} alt="layout" className='layout' />
                </div>
            </div>
        </section>
    )
}

export default CJLarge