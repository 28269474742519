import { useEffect, useState } from 'react';
import axios from 'axios';
import { RESOURCE_API } from '../../../../Utilities/APIs';

import './SingleResource.css'

function SingleResource({ parentId }) {

    const [resource, setResource] = useState(null);

    useEffect(() => {

        async function getResource() {
            const { data } = await axios.get(RESOURCE_API + 'getSingleResource/' + parentId);
            setResource(data);
        }
        getResource()

    }, [parentId])

    return (
        <div className='single_res'>
            <h3>{resource && resource?.name}</h3>
            <p>{resource && resource?.description}</p>
        </div>
    )
}

export default SingleResource