import { useEffect, useState } from 'react';
import axios from 'axios';
import { CLIENT_JOURNEY_API } from '../../../../Utilities/APIs';

import CJLarge from './CJLarge/CJLarge';
import CJSmall from './CJSmall/CJSmall';
// import img1 from '../../../../Assets/Images/client-journey/strategy.svg'
// import img2 from '../../../../Assets/Images/client-journey/location.svg'
// import img3 from '../../../../Assets/Images/client-journey/support.svg'
// import img4 from '../../../../Assets/Images/client-journey/phone-call.svg'
// import img5 from '../../../../Assets/Images/client-journey/research.svg'
// import img6 from '../../../../Assets/Images/client-journey/process.svg'
// import img7 from '../../../../Assets/Images/client-journey/accelerate.svg'


import './ClientJourny.css'

function ClientJourny({isHeading}) {

    const [clientJournies, setClientJournies] = useState(null);

    useEffect(() => {
        async function getClientJournies() {
            const { data } = await axios.get(CLIENT_JOURNEY_API)
            setClientJournies(data)
        }

        getClientJournies()
    }, [])


    return (
        <>
            <CJLarge
                clientJournies={clientJournies}
                isHeading={isHeading}
            />
            <CJSmall
                clientJournies={clientJournies}
                isHeading={isHeading}
            />
        </>
    )
}

export default ClientJourny