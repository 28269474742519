import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { IMAGE_URL, TESTIMONIAL_API } from '../../../Utilities/APIs';

// import Banner from '../../Layouts/Banner/Banner'
import { ReactComponent as Quote } from '../../../Assets/Images/quote.svg'

import './SingleTestimonial.css'

function SingleTestimonial() {

  const {id} = useParams();
  
  const [singleTestimonial, setSingleTestimonial] = useState({});

  useEffect(() => {
    async function getSingleTestimonial() {
      const { data } = await axios.get(TESTIMONIAL_API + '/getSingleTestimonial/' + id)
      setSingleTestimonial(data)
    }

    getSingleTestimonial()
  }, [id])


  return (
    <main className="st_page">
      {/* <Banner title="Details Testimonial" /> */}
      <section className="single_testimonial pb_9 pt_9">
        <div className="container">
          <div className="st_name_pic">
            <div className="img_box">
              <img src={singleTestimonial && IMAGE_URL + singleTestimonial.image} alt="poster" />
            </div>
            <h5>{singleTestimonial && singleTestimonial.name}</h5>
          </div>
          <div className="desc_box">
            <Quote className="quote" />
            <p>{singleTestimonial && singleTestimonial.description}</p>
            </div>
        </div>
      </section>
    </main>
  )
}

export default SingleTestimonial