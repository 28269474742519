import { useEffect, useState } from 'react';
import axios from 'axios';
import { APPROACH_API } from '../../../../Utilities/APIs';

import './OurApproach.css'

function OurApproach() {

  const [OurApproach, setOurApproach] = useState({});

  useEffect(() => {
    async function getOurApproach() {
      const { data } = await axios.get(APPROACH_API)
      setOurApproach(data[0])
    }

    getOurApproach()
  }, [])


  return (
    <section className="our_approach py_9">
      <div className="container">
        <h1 className="title_1 pb_4">Our <span>Approach </span></h1>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: OurApproach && OurApproach.description }} />
        </div>
      </div>
    </section>
  )
}

export default OurApproach