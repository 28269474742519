import { IMAGE_URL } from "../../../../../Utilities/APIs"

import './CJSmall.css'

function CJSmall({ clientJournies, isHeading }) {
    return (
        <section className="client_journey small py_9">
            <div className="container">
                {isHeading &&
                    <h1 className="title_1 center pb_9">Client <span>Journey</span></h1>
                }
                <div className="cj_grids">
                    {clientJournies &&
                        clientJournies?.map(clientJourney => (
                            <div className="grid" key={clientJourney._id}>
                                <div className="content" >
                                    <h5>{clientJourney.name}</h5>
                                    <p>{clientJourney.description}</p>
                                </div>
                                <div className="icon_box_wrapper">
                                    <div className="icon_box">
                                        <img src={IMAGE_URL + clientJourney.image} alt="icon" />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default CJSmall