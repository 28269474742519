import { useEffect, useState } from 'react';
import axios from 'axios';
import { RECENT_PURCHASE_API } from '../../../Utilities/APIs';

// import Banner from '../../Layouts/Banner/Banner'
import RPCard from './RPCard/RPCard'

import './RecentPurchase.css'

function RecentPurchase() {

  const [recentPurchases, setRecentPurchases] = useState(null);

  useEffect(() => {
    async function getRecentPurchases() {
      const { data } = await axios.get(RECENT_PURCHASE_API)
      setRecentPurchases(data)
    }

    getRecentPurchases()
  }, [])


  return (
    <main className="rp_page">
      {/* <Banner title="Recent Purchase" /> */}
      <section className="recent_purchase py_9">
        <div className="container">
          <div className="grid_container">
            {recentPurchases &&
              recentPurchases?.map(recentPurchase => (
                <RPCard
                  key={recentPurchase._id}
                  recentPurchase={recentPurchase}
                />
              ))}
          </div>
        </div>
      </section>
    </main>
  )
}

export default RecentPurchase