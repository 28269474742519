import { ReactComponent as BedIcon } from '../../../../Assets/Icons/bed.svg'
import { ReactComponent as BathIcon } from '../../../../Assets/Icons/bath.svg'
import { ReactComponent as GarageIcon } from '../../../../Assets/Icons/garage.svg'
import { ReactComponent as SqmIcon } from '../../../../Assets/Icons/sqm.svg'
// import thumbImg from '../../../../Assets/Images/recent-purchases/rp-img-1.png'
import { IMAGE_URL, RECENT_PURCHASE_FEATURES_API } from '../../../../Utilities/APIs'

import './RPCard.css'
import { useEffect, useState } from 'react'
import axios from 'axios'

function RPCard({ recentPurchase }) {
    const [recentPurchaseFeatures, setRecentPurchaseFeatures] = useState(null);

    useEffect(() => {
        async function getRecentPurchasesFeatures() {
            const { data } = await axios.get(RECENT_PURCHASE_FEATURES_API + 'getRecentPurchaseFeatures')
            const rpf = data?.filter(d => d.recentPurchase?._id === recentPurchase?._id)
            setRecentPurchaseFeatures(rpf)
        }

        getRecentPurchasesFeatures()
    }, [recentPurchase._id])

    return (
        <div className='rp_card'>
            <img src={IMAGE_URL + recentPurchase?.image} alt="thumb" />
            <div className="content">
                <div className="d_flex">
                    <div className="d_flex">
                        <BedIcon />
                        <p><span>{recentPurchase?.bed}</span></p>
                    </div>
                    <div className="d_flex">
                        <BathIcon />
                        <p><span>{recentPurchase?.bath}</span></p>
                    </div>
                    <div className="d_flex">
                        <GarageIcon />
                        <p><span>{recentPurchase?.garage}</span></p>
                    </div>
                </div>

                <h2>{recentPurchase?.name}</h2>
                {
                    recentPurchaseFeatures?.map(rpf =>
                        <p className="desc" key={rpf._id}><span style={{fontSize: '48px'}}>.</span> {rpf?.name}</p>
                    )
                }
            </div>

            <div className="meta_box">
                <div className="d_flex">
                    <div>
                        <h4>Price: ${recentPurchase?.price}</h4>
                        <p className='rent'>Rent ${recentPurchase?.rent}/week</p>
                    </div>
                    <div className="d_flex">
                        <div className="d_flex">
                            <SqmIcon />
                            <p><span>{recentPurchase?.sqFt} </span> m<sup>2</sup></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RPCard