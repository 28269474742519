import { Link } from 'react-router-dom'
import { IMAGE_URL } from '../../../../Utilities/APIs'

import './TMCard.css'

function TMCard({testimonial}) {
    return (
        <div className='tm_card'>
            <div className="item">
                <div className="name_pic">
                    <div className="img_box">
                        <img src={IMAGE_URL + testimonial?.image} alt="poster" />
                    </div>
                    <h5>{testimonial?.name}</h5>
                </div>
            </div>
            <div className="item">
                <div className="content">
                    <p>{testimonial?.description.slice(0, 300)}</p>
                    <Link to={'/singleTestimonial/' + testimonial?._id}>Read more</Link>
                </div>
            </div>
        </div>
    )
}

export default TMCard