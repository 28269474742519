import { useState } from 'react';

import NavLarge from './NavLarge/NavLarge'
import NavSmall from './NavSmall/NavSmall'

import './Navbar.css'

function Navbar() {

  const [showSmallNav, setShowSmallNav] = useState(false);

  const smallNavToggle = () => {
    setShowSmallNav(prevState => !prevState)
  }

  return (
    <section className="navbar">
      <NavLarge
        smallNavToggle={smallNavToggle}
      />
      <NavSmall
        showSmallNav={showSmallNav}
        smallNavToggle={smallNavToggle}
      />
    </section>
  )
}

export default Navbar