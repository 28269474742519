import { useEffect, useState } from 'react';
import axios from 'axios';
import { FEATURED_RECENT_PURCHASE_API } from '../../../../Utilities/APIs';
import { IMAGE_URL } from '../../../../Utilities/APIs'

// import img1 from '../../../../Assets/Images/recent-purchases/rp-img-1.png'
// import img2 from '../../../../Assets/Images/recent-purchases/rp-img-2.png'
// import img3 from '../../../../Assets/Images/recent-purchases/rp-img-3.png'
// import img4 from '../../../../Assets/Images/recent-purchases/rp-img-4.png'
// import img5 from '../../../../Assets/Images/recent-purchases/rp-img-5.png'
// import img6 from '../../../../Assets/Images/recent-purchases/rp-img-6.png'

import './FeaturedPurchases.css'

function FeaturedPurchases() {

  const [fRPurchases, setFRPurchases] = useState(null);

  useEffect(() => {
    async function getFRPurchases() {
      const { data } = await axios.get(FEATURED_RECENT_PURCHASE_API)
      setFRPurchases(data)
    }

    getFRPurchases()
  }, [])


  return (
    <section className="featured_purchases py_9">
      <div className="container">
        <h1 className="title_1 center pb_9">Acceler Investing <span>Recent</span> Purchases</h1>
        <div className="grid_container">
          {fRPurchases &&
            fRPurchases?.map(fRPurchas => (
              <div className="grid_item" key={fRPurchas._id}>
                <img src={IMAGE_URL + fRPurchas?.recentPurchase?.image} alt="thumb" />
                <div className="content">
                  <h4>{fRPurchas?.recentPurchase?.name}</h4>
                  <p>Price: ${fRPurchas?.recentPurchase?.price} / Rent ${fRPurchas?.recentPurchase?.rent}/week</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default FeaturedPurchases