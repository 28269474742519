import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom'
import AppContext from '../../../../AppContext/AppContext';

import { ReactComponent as CloseIcon } from '../../../../Assets/Icons/close-icon.svg'
import logo from '../../../../Assets/Logos/nav-logo-lg.svg'

import './NavSmall.css'

function NavSmall({ showSmallNav, smallNavToggle }) {

  const { setShowConsultationForm } = useContext(AppContext)

  return (
    <div className={`nav_small ${showSmallNav && 'show'}`}>
      <div className="nav_smal_overlay" onClick={smallNavToggle}></div>
      <div className="nav_small_content">
        <button type='button' className='btn_close' onClick={smallNavToggle}>
          <CloseIcon />
        </button>
        <div className="logo_wrapper">
          <Link to='/' onClick={smallNavToggle}>
            <img src={logo} alt="brand" className='logo' />
          </Link>
        </div>
        <nav>
          <NavLink to='/'>home</NavLink>
          <NavLink to='/aboutUs' onClick={smallNavToggle}>About Us</NavLink>
          <NavLink to='/meetTheTeam' onClick={smallNavToggle}>Meet The Team</NavLink>
          <NavLink to='/process' onClick={smallNavToggle}>Process</NavLink>
          <NavLink to='/recentPurchase' onClick={smallNavToggle}>Recent Purchase</NavLink>
          <NavLink to='/testimonials' onClick={smallNavToggle}>Testimonials</NavLink>
          <NavLink to='/contactUs' onClick={smallNavToggle}>Contact Us</NavLink>
        </nav>
        <div className="text_center">
          <button
            type='button'
            className='btn fill'
            onClick={() => {
              setShowConsultationForm(true)
              smallNavToggle()
            }}
          >
            <span>Free Consultation</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default NavSmall