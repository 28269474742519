// import Banner from '../../Layouts/Banner/Banner'
// import AgentDesc from './AgentDesc/AgentDesc'
import OurApproach from './OurApproach/OurApproach'
import WhoWeAre from './WhoWeAre/WhoWeAre'

import './AboutUs.css'

function AboutUs() {
  return (
    <main className="about_us_page">
      {/* <Banner title="About Us" /> */}
      {/* <AgentDesc /> */}
      <WhoWeAre />
      <OurApproach />
    </main>
  )
}

export default AboutUs