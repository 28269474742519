// import mttImg from '../../../../Assets/Images/mtt-img.png'
import { IMAGE_URL } from '../../../../Utilities/APIs'

import './MTTCard.css'

function MTTCard({ mTTeam }) {
    return (
        <div className='mtt_card'>
            <div className="item">
                <img src={mTTeam && IMAGE_URL + mTTeam.image} alt="thumb" />
            </div>
            <div className="item">
                <div className="content">
                    <h4>{mTTeam && mTTeam.name}</h4>
                    <h6>{mTTeam && mTTeam.designation}</h6>
                    <div className="editor_box">
                        <div dangerouslySetInnerHTML={{ __html: mTTeam && mTTeam.description }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MTTCard