import { useEffect, useState } from 'react';
import axios from 'axios';
import { FAQ_API } from '../../../Utilities/APIs';

import './FAQs.css'

function FAQs() {

    const [FAQs, setFAQs] = useState(null);
    const [activeFAQId, setActiveFAQId] = useState('');
    const [activeFAQ, setActiveFAQ] = useState(false)

    useEffect(() => {

        async function getFAQs() {
            const { data } = await axios.get(FAQ_API);
            setFAQs(data);
            setActiveFAQId(data[0]?._id);
        }
        getFAQs()

    }, [])


    return (
        <section className='faq_page py_9'>
            <div className="container">
                <div className="heading pb_9">
                    <h1 className="title_1">Frequently <span> Asked </span> Questions</h1>
                    <p>Have questions? We’re here to help.</p>
                </div>
                <div className="accordion">
                    {FAQs &&
                        FAQs?.map(faq => (
                            <div className={`accordion_item ${(faq._id === activeFAQId) && activeFAQ && 'open'}`} key={faq._id}>
                                <div className="accordion_header">
                                    <h4
                                        // onClick={() => setActiveFAQId(faq._id)}
                                        onClick={() => {
                                            setActiveFAQId(faq._id)
                                            setActiveFAQ(prev => !prev)
                                        }}
                                    >
                                        {faq && faq?.name}
                                    </h4>
                                </div>
                                <div className="accordion_body">
                                    <div className="accordion_body_inner">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: faq && faq?.description }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default FAQs