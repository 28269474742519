import { useContext } from 'react'
import AppContext from '../../../../AppContext/AppContext'

import './ResCard.css'

function ResCard({ resource, setParentId }) {

  const { setShowSingleResource } = useContext(AppContext)
  return (
    <div className='res_card'>
      <div className="res_card_iner">
        <h3>{resource && resource?.name}</h3>
        {/* <p>{resource && resource?.description.length > 20 ? resource?.description.slice(0, 20) + '...' : resource?.description}</p> */}
        <p>{resource && resource?.description}</p>

        <div className='file_link'>
          {/* <a href={`${resource && resource?.link}`} target='_blanck'><span>file link</span></a> */}
          <button 
          type='button'
          onClick={() => {
            setParentId(resource && resource?._id)
            setShowSingleResource(true)
          }}
          ><span>read more</span></button>
        </div>
      </div>
    </div>
  )
}

export default ResCard