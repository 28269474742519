import { useEffect, useState } from 'react';
import axios from 'axios';
import { PROCESS_API } from '../../../Utilities/APIs';

// import Banner from '../../Layouts/Banner/Banner'
import ProcessCard from './ProcessCard/ProcessCard'

import './Process.css'
import ClientJourny from '../Home/ClientJourny/ClientJourny';

function Process() {

  const [process, setProcess] = useState(null);

  useEffect(() => {
    async function getProcess() {
      const { data } = await axios.get(PROCESS_API)
      setProcess(data)
    }

    getProcess()
  }, [])


  return (
    <main className="process_page">
      {/* <Banner title="Process" /> */}
      <ClientJourny />
      <section className="process py_9">
        <div className="container">
          {process &&
            process?.map(proces => (
              <ProcessCard
                key={proces._id}
                proces={proces} />
            ))}
        </div>
      </section>
    </main>
  )
}

export default Process