import { useEffect, useState } from 'react';
import axios from 'axios';
import { ABOUT_API } from '../../../../Utilities/APIs';

import './WhoWeAre.css'

function WhoWeAre() {

    const [about, setAbout] = useState({});

    useEffect(() => {
        async function getAbout() {
            const { data } = await axios.get(ABOUT_API)
            setAbout(data[0])
        }

        getAbout()
    }, [])


    return (
        <section className='who_we_are py_9'>
            <div className="container">
                <div>
                    {/* <div className="item">
                        <img src={about && IMAGE_URL + about.image} alt="thumb" />
                    </div> */}
                    <div className="item">
                        <h1 className="title_1 pb_4">Who <span>We</span> Are</h1>
                        <div
                        dangerouslySetInnerHTML={{ __html: about && about.description }}
                    />
                        {/* <p>{about && about.description}</p> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhoWeAre