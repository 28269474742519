import { useEffect, useState } from 'react';
import axios from 'axios';
import { TESTIMONIAL_API } from '../../../Utilities/APIs';

// import Banner from '../../Layouts/Banner/Banner'
import TMCard from './TMCard/TMCard'

import './Testimonials.css'

function Testimonials() {

  const [testimonials, setTestimonials] = useState(null);

  useEffect(() => {
    async function getTestimonials() {
      const { data } = await axios.get(TESTIMONIAL_API)
      setTestimonials(data)
    }

    getTestimonials()
  }, [])


  return (
    <main className="testimonials_page">
      {/* <Banner title="Testimonials" /> */}
      <section className="testimonials py_9">
        <div className="container">
          {testimonials &&
            testimonials?.map(testimonial => (
              <TMCard
                key={testimonial._id}
                testimonial={testimonial}
              />
            ))}
        </div>
      </section>
    </main>
  )
}

export default Testimonials